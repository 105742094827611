<template>
  <v-app>
    <p class="pa-5" v-show="redirecting">Redirecionando ...</p>
    <div class="loading-container" v-if="loadingPage">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-else v-show="!redirecting" class="login-container">
      <v-img
        src="@/assets/top_ball.png"
        max-height="150"
        max-width="150"
        class="top_ball"
      ></v-img>
      <div class="custom-section">
        <v-carousel
          :show-arrows="false"
          v-model="slide"
          cycle
          hide-delimiter-background
          height="100%"
        >
          <v-carousel-item v-for="(page, i) in pages" :key="i" v-html="page">
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="form-section">
        <v-card elevation="0" :loading="loading">
          <v-card-text class="pa-2">
            <v-container class="login-naja">
              <v-row align="center" justify="center">
                <v-img :src="logo" max-height="150" max-width="150"></v-img>
              </v-row>
            </v-container>
            <v-card-title class="justify-center mt-2">
              Bem-vindo de volta
            </v-card-title>
            <v-form class="mt-3" @submit.prevent="signin">
              <button type="submit" class="hide"></button>
              <v-alert v-show="error" outlined dense type="error" class="mb-6">
                Usuário ou senha incorretos
              </v-alert>
              <v-text-field
                label="Usuário"
                autofocus
                outlined
                name="username"
                v-model="credentials.username"
              ></v-text-field>
              <v-text-field
                v-model="credentials.password"
                name="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                outlined
                hide-details="auto"
                @click:append="showPassword = !showPassword"
                class="mb-2"
              >
                <template v-slot:message="{ message }">
                  <span v-html="message"></span>
                </template>
              </v-text-field>
              <div class="d-flex justify-space-between align-center">
                <v-checkbox label="Lembre-se de mim"></v-checkbox>
                <router-link to="/reset-password">
                  Esqueceu sua senha?
                </router-link>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="mb-4">
            <v-btn @click="signin" block elevation="0" color="primary">
              Entrar
            </v-btn>
          </v-card-actions>
          <v-container class="login-naja">
            <v-row align="center" justify="center">
              <v-img
                src="@/assets/naja_logo.png"
                max-height="150"
                max-width="150"
              ></v-img>
            </v-row>
          </v-container>
          <v-img
            src="@/assets/ball.png"
            max-height="150"
            max-width="150"
            class="ball"
          ></v-img>
        </v-card>
      </div>
    </div>
  </v-app>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
//eslint-disable-next-line
const CRM_REGEX = /[a-zA-Z]{2}[\/]\d*/gm
export default {
  data() {
    return {
      logo: null,
      pages: [],
      slide: 0,
      loadingPage: true,
      loading: false,
      showPassword: false,
      error: false,
      credentials: {
        password: null,
        username: null,
        rules: null,
      },
      app: null,
      redirectTo: null,
      redirecting: false,
      rules: {
        required: value => !!value || 'Obrigatório',
      },
    }
  },

  mounted() {
    this.fetch()
    this.app = this.$route.query.app
    this.redirectTo = this.$route.query.redirect
    if (this.$route.query.rules) {
      this.credentials.rules = Array.isArray(this.$route.query.rules)
        ? this.$route.query.rules
        : [this.$route.query.rules]
    }
  },

  methods: {
    async fetch() {
      try {
        this.loadingPage = true
        const organizationResponse = await Api.getOrganizationInfo()
        this.logo = organizationResponse.data.logo

        const slidesResponse = await Api.loginSlides()
        this.pages = slidesResponse.data.slides.map(x => x.body)
      } finally {
        this.loadingPage = false
      }
    },
    signin() {
      this.error = false
      this.loading = true

      if (CRM_REGEX.test(this.credentials.username)) {
        this.credentials.username = this.credentials.username.replace('/', '')
      }

      Api.authenticate(this.credentials)
        .then(async response => {
          this.redirecting = true
          const token = response.data.auth_token

          window.location.replace(`${this.redirectTo}?token=${token}`)
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.ball {
  position: fixed;
  right: 0;
  bottom: 0;
}

.top_ball {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: none;
}

.loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.login-container {
  height: 100%;
  display: flex;

  .v-card__title {
    color: #1e1e1e;
    font-weight: 600;
    font-size: 2.45rem;
  }

  .form-section {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0rem 2em 2em;

    .v-card {
      width: 400px;
    }
  }

  .custom-section {
    background-color: #f4f4f8;
    flex: 1;
    height: 100%;
  }
}

.v-application .transparent {
  border-color: black !important;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .ball {
    display: none;
  }

  .top_ball {
    display: block;
  }
  .login-container {
    display: flex;
    flex-direction: column-reverse;

    .v-card__title {
      font-size: 1.623rem;
    }

    .v-card {
      box-shadow: none !important;
    }
  }
}
</style>
